

import React from 'react';
import { Util } from '../../base/util.js'
import { NameGen } from '../../base/namegen.js'


export class CreationBaseDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = { selection: {}, data: null, gameID: null, stage: 1, failed: true, name: '', pass: '', pass2: '', email: '', gender: '', tos: false, nl_game: true, nl_ire: true };
    }

    componentDidMount() {
        this.initCreation();
    }

    initCreation() {
        let t = this;
        let gameID = this.props.nexus.active_game_id();
        this.setState({stage:1,selection:{},loadFailed:null});
        if (this.state.gameID && this.state.data && (this.state.gameID === gameID)) return;  // already got the right game? nothing to do here
        
        this.setState({data:null});
        // need to pull in the data
        t.loadData();
    }

    onGameSelect(gameID, info) {
        let nex = this.props.nexus;
        nex.set_current_game (gameID, info, true);
        this.props.onCancel();
        // this.initCreation();
    }

    loadData() {
        let t = this;
        let gameID = this.props.nexus.active_game_id();
        let server = this.props.nexus.gameinfo().server_name();
        var dataurl = 'https://www.' + server + '/local/nexus_data/creation_data.json';
        this.fetchFile(dataurl).then((result) => {
            if (result.ok)
                return Promise.resolve(result.json());
            else
                return Promise.reject(result.statusText);
        }).then((data) => {
            data = t.prepare_data(data);
            t.setState({data:data, stage:0, selection:{}, gameID:gameID}, () => t.enter_stage(1));
        }).catch((error) => {
            t.setState({loadFailed: error.message});
        });
    }

    stages() {
        return this.props.nexus.gameinfo().creation_stages();
    }

    valid_choice(type, id) {
        return this.props.nexus.gameinfo().creation_valid_choice(type, id, this);
    }
    
    creation_step(stage) {
        return this.props.nexus.gameinfo().creation_step(stage);
    }

    creation_title(stage) {
        return this.props.nexus.gameinfo().creation_title(stage);
    }

    creation_short_title(stage) {
        return this.props.nexus.gameinfo().creation_short_title(stage);
    }

    prepare_data(data) {
        let nex = this.props.nexus;
        return nex.gameinfo().creation_prepare_data(data);
    }

    genders() {
        return this.props.nexus.gameinfo().genders();
    }
    element_genderreq(type, e) {
        if (this.props.nexus.gameinfo().creation_genderreq) return this.props.nexus.gameinfo().creation_genderreq (type, e);
        return null;
    }
    element_genderreq_text(type, e) {
        if (this.props.nexus.gameinfo().creation_genderreq_text) return this.props.nexus.gameinfo().creation_genderreq_text (type, e);
        return null;
    }
    gender_enforced() {
        for (let type in this.state.selection) {
            let obj = this.selected_obj(type);
            if (!obj) continue;
            let gender = this.element_genderreq(type, obj);
            if (gender) return gender;
        }
        return null;
    }
    gender_enforced_text() {
        for (let type in this.state.selection) {
            let obj = this.selected_obj(type);
            if (!obj) continue;
            let text = this.element_genderreq_text(type, obj);
            if (text) return text;
        }
        return null;
    }

    element_restriction(type, e) {
        if (this.props.nexus.gameinfo().creation_restriction) return this.props.nexus.gameinfo().creation_restriction (type, e, this);
        return null;
    }

    element_restriction_text(type, e) {
        if (this.props.nexus.gameinfo().creation_restriction_text) return this.props.nexus.gameinfo().creation_restriction_text (type, e, this);
        return null;
    }

    show_age() {
        if (!this.props.nexus.gameinfo().show_age) return false;
        return this.props.nexus.gameinfo().show_age();
    }
    age_limits() {
        if (!this.props.nexus.gameinfo().age_limits) return false;
        return this.props.nexus.gameinfo().age_limits(this.state.data, this);
    }

    creation_name_hint() {
        if (!this.props.nexus.gameinfo().creation_name_hint) return 'A good name feels fantasy, but isn\'t from a book or movie.';
        return this.props.nexus.gameinfo().creation_name_hint();
    }
    
    display_list_group(e, type) {
        if (!this.props.nexus.gameinfo().display_list_group) return false;
        return this.props.nexus.gameinfo().display_list_group(e, type);
    }

    creation_stage_info(type) {
        if (this.props.nexus.gameinfo().creation_stage_info) return this.props.nexus.gameinfo().creation_stage_info(type);
        return 'You will be able to change your ' + type + ' selection later.';
    }

    creation_nameform_text() {
        if (this.props.nexus.gameinfo().creation_nameform_text) return this.props.nexus.gameinfo().creation_nameform_text(this);
        let text = 'You are %R of the %P %p, belonging to %C.';
        for (let stage = 1; stage <= this.stages(); ++stage) {
            let type = this.creation_step(stage);
            if (type === 'name') continue;
            let obj = this.selected_obj(type);
            if (!obj) continue;
            if (type === 'race') {
                text = text.replace ('%R', Util.add_article (obj.name));
            } else if ((type === 'city') || (type === 'faction')) {
                text = text.replace ('%C', obj.name);
            } else if ((type === 'class') || (type === 'profession')) {
                text = text.replace ('%P', obj.name);
                text = text.replace ('%p', type);
            }
        }
        return text;
    }
    creation_nameform_image() {
        if (this.props.nexus.gameinfo().creation_nameform_image) return this.props.nexus.gameinfo().creation_nameform_image(this.state.data, this);
        return null;
    }

    creation_data() {
        return this.props.nexus.gameinfo().creation_data(this);
    }

    obj_index(type, id) {
        let objs = this.state.data[type];
        if (!objs) return -1;
        for (let idx = 0; idx < objs.length; ++idx)
            if (objs[idx].id === id) return idx;
        return -1;
    }

    obj_with_index(type, idx) {
        if (idx < 0) return null;
        let objs = this.state.data[type];
        if (!objs) return null;
        return objs[idx];
    }

    obj_list(type) {
        return this.state.data[type];
    }

    obj_allowed_list(type) {
        let res = [];
        let objs = this.state.data[type];
        if (!objs) return res;
        for (let idx = 0; idx < objs.length; ++idx) {
            let e = objs[idx];
            if (!this.valid_choice(type, e.id)) continue;
            res.push (e);
        }
        return res;
    }

    get_obj(type, id) {
        return this.obj_with_index(type, this.obj_index(type, id));
    }

    selected_obj_index(type) {
        let sel = this.state.selection[type];
        if ((sel === undefined) || (sel == null)) return -1;
        return this.obj_index(type, sel);
    }

    selected_obj(type) {
        let idx = this.selected_obj_index(type);
        return this.obj_with_index(type, idx);
    }

    set_failed_state(failed) {
        this.setState({failed:failed});
    }

    error_name(name) {
        if ((!name) || (!name.length)) return null;
        if (name.length < 3) return 'Your character\'s name must be at least 3 letters long.';
        if (name.length > 12) return 'Your character\'s name cannot be longer than 12 characters.';
        // only allow a-z letters
        for (let i = 0; i < name.length; ++i) {
            let code = name.charCodeAt(i);
            let letter = false;
            if ((code >= 65) && (code <= 90)) letter = true;
            if ((code >= 97) && (code <= 122)) letter = true;
            if (!letter) return 'Your character\'s name can only include letters from A to Z.'
        }
        return null;
    }

    error_pass(pass, name) {
        if ((!pass) || (!pass.length)) return null;
        if (name && (name.length >= 3) && (pass.indexOf(name) >= 0)) return 'Your password cannot include your character\'s name.';
        if (pass.length < 8) return 'Your password needs to contain at least 8 characters.';
        if (pass.indexOf(' ') >= 0) return 'Your password cannot contain spaces.';
        return null;
    }
    
    error_pass2(pass2, pass) {
        if ((!pass2) || (!pass2.length)) return null;
        if (pass !== pass2) return 'The passwords do not match.';
        return null;
    }

    error_age(age) {
        if (!this.show_age()) return null;
        if (!age) return 'Please enter your chatacter\'s starting age.';
        age = parseInt(age);
        let limits = this.age_limits();
        if (age < limits[0]) return 'Your character must be at least '+limits[0]+' years old.';
        if (age > limits[1]) return 'Your character cannot be older then '+limits[0]+' years.';
        return null;
    }

    error_email(email) {
        if ((!email) || (!email.length)) return null;
        if (!Util.valid_email (email)) return 'Please enter a valid e-mail address.';
        return null;
    }
    
    error_gender(gender) {
        if (this.gender_enforced()) return null;  // no error if the gender is enforced
        if ((!gender) || (!gender.length)) return 'Please select your character\'s gender.';
        let list = this.genders();
        let found = false;
        for (let i = 0; i < list.length; ++i)
            if (list[i].toLowerCase() === gender.toLowerCase())
                found = true;
        if (!found) return 'Please select a valid gender.';
        return null;
    }

    error_dob(dob) {
        if (!dob) return 'ERROR';
        // validate that DOB
        let match = new RegExp('^([0-9]{4})-([0-9]{2})-([0-9]{2})$');
        let res = dob.match (match);
        if (!res) return 'ERROR';
        let y = parseInt(res[1]);
        let m = parseInt(res[2]);
        let d = parseInt(res[3]);
        if ((d < 1) || (d > 31)) return 'The day must be in the 1-31 range.';
        if ((m < 1) || (m > 12)) return 'The month must be in the 1-12 range.';
        if (y < 1900) return 'I doubt that you are -that- old.';
        if (y > new Date().getFullYear() - 5) return 'You are too young.';
        return false;
    }

    validate_nameform() {
        let t = this;
        let ok = true;

        // name
        // Names are checked in validate_name in addition to here
        let st = {};
        let err = this.error_name (t.state.name);
        st.nameMessage = err ? err : null;
        st.errorName = err ? true : false;
        if (err) ok = false;
        if (t.validated_name !== t.state.name) ok = false;   // need a validated name
        
        err = this.error_pass (t.state.pass, t.state.name);
        st.passMessage = err ? err : null;
        st.errorPass = err ? true : false;
        if (err || (!t.state.name)) ok = false;

        err = this.error_pass2 (t.state.pass2, t.state.pass);
        st.pass2Message = err ? err : null;
        st.errorPass2 = err ? true : false;
        if (err || (!t.state.pass)) ok = false;

        err = this.error_age (t.state.age);
        st.ageMessage = err ? err : null;
        st.errorAge = err ? true : false;
        if (err) ok = false;

        // Similarly, e-mails are checked in validate_email in addition to here
        err = this.error_email (t.state.email);
        st.emailMessage = err ? err : null;
        st.errorEmail = err ? true : false;
        if (err || (!t.state.email)) ok = false;
        if (!t.state.emailChecked) ok = false;
        if (t.validated_email !== t.state.email) ok = false;   // need a validated email

        // If the e-mail is not registered, we need the regis info as well.
        if (!t.state.emailRegistered) {
            st.errorFirstName = false;
            st.errorLastName = false;
            st.errorDOB = false;
            if (!t.state.firstName) {
                ok = false;
                st.errorFirstName = true;
            }
            if (!t.state.lastName) {
                ok = false;
                st.errorLastName = true;
            }
            
            err = this.error_dob (t.state.DOB);
            console.log(err);
            if (err === 'ERROR') err = 'Please enter the date in the YYYY-MM-DD format.';
            st.DOBMessage = err ? err : null;
            st.errorDOB = err ? true : false;
            if (err) ok = false;
        }

        err = this.error_gender(t.state.gender);
        st.genderMessage = err ? err : null;
        st.errorGender = err ? true : false;
        if (err) ok = false;
        
        if (t.state.tos) {
            st.errorTos = false;
            st.tosMessage = null;
        } else {
            st.errorTos = true;
            st.tosMessage = 'You need to agree to the terms of service to continue.';
            ok = false;
        }

        t.setState(st);
        return ok;
    }

    validateStage(stage) {
        let type = this.creation_step (stage);
        if (type === 'name') {
            return this.validate_nameform();
        } else
        {
            let sel = this.state.selection[type];
            if ((sel === undefined) || (sel === null)) return false;
            if (!this.valid_choice (type, sel)) return false;
        }
        return true;
   }

    validate() {
        let stage = this.state.stage;
        for (let s = 1; s <= stage; ++s) {
            if (this.validateStage(s)) continue;
            
            this.set_failed_state (true);
            if (s < stage) this.enter_stage(s);
            return false;
        }
        this.set_failed_state(false);
        return true;
    }

    
    enter_stage(stage) {
        var t = this;

        if (stage > t.stages()) {
            t.do_creation();
            return;
        }
/*
        // select a random valid entry
        let type = t.creation_step (stage);
        if (type !== 'name') {
            let opts = [];
            let items = this.state.data[type];
            if (!items) items = [];
            let cur = this.state.selection[type];
            let have_valid = false;
            for (let i = 0; i < items.length; ++i)
            {
                let e = items[i];
                if (!this.valid_choice(type, e.id)) continue;
                if (cur && e.id === cur) have_valid = true;
                opts.push (e);
            }
            if (!have_valid) {
                let e = opts[Math.floor(Math.random()*opts.length)];
                this.onListItemClick(type, e);
            }
        }
*/

        let st = {stage: stage};
        if (t.show_age()) {
            let limits = t.age_limits();
            // if the age is outside the limits, or not set, set it to its default value
            if ((!t.state.age) || (t.state.age < limits[0]) || (t.state.age > limits[1]))
                st.age = limits[2];
        }
        t.setState(st, ()=>t.validate());
        t.validate();
    }

    
/** List rendering */

    element_title(type, e) {
        if (this.props.nexus.gameinfo().element_title) return this.props.nexus.gameinfo().element_title (type, e, this);

        let name = e.name;
        let names = this.element_split_name(e);
        if (names[1]) name = names[0];

        if (type === 'race') return name;
        if ((type === 'city') || (type === 'faction')) {
//            if (e.title && e.title.length)
//                return Util.ucfirst(e.title);
            return Util.ucfirst(name);
        }
        if (type === 'guild') return 'The ' + Util.ucfirst(name);
        if ((type === 'class') || (type === 'profession')) {
            name = 'The ' + Util.ucfirst(name);
            if (name.slice(-1) === 'y') name = name.slice(0,-1) + 'ie';
            else if ((name.slice(-1) === 's') || (name.slice(-2) === 'ch')) name += 'e';
            name += 's';
            return name;
        }
    }

    element_subtitle(type, e) {
        let names = this.element_split_name(e);
        if (names[1]) return names[1];
        if ((type === 'guild') || (type === 'class') || (type === 'profession')) return e.group;
        return null;
    }

    element_split_name(e) {
        let name = Util.ucfirst(e.name);
        if (e.shortName) name = Util.ucfirst(e.shortName);
        let subtitle;
        let pos1 = name.indexOf (' (');
        if (pos1 > 0) {
            subtitle = name.substr (pos1 + 2);
            name = name.substr (0, pos1);
            let pos2 = subtitle.indexOf (')');
            if (pos2) subtitle = subtitle.substr (0, pos2);
        }
        return [ name, subtitle ];
    }

    onListItemClick(type, e) {
        let id = e.id;
        if (!this.valid_choice(type, id)) return;
        let sel = this.state.selection;
        sel[type] = e.id;
        sel['imageid'] = 0;
        this.validate();
        this.setState({selection: sel});
    }

    listGetPrevious(type) {
        if (!this.state.data) return null;
        let items = this.state.data[type];
        if (!items) return null;

        let curidx = this.selected_obj_index(type);
        if (curidx < 0) return null;

        let idx = curidx;
        do {
            idx--;
            if (idx < 0) idx = items.length - 1;
            if (idx === curidx) return null;  // no valid choice
            let obj = items[idx];
            if (!this.valid_choice(type, obj.id)) continue;
            
            // we have a valid choice now
            return idx;
        } while (idx !== curidx);
        return null;
    }
    
    listGetNext(type) {
        if (!this.state.data) return null;
        let items = this.state.data[type];
        if (!items) return null;

        let curidx = this.selected_obj_index(type);
        if (curidx < 0) return null;

        let idx = curidx;
        do {
            idx++;
            if (idx >= items.length) idx = 0;
            if (idx === curidx) return null;  // no valid choice
            let obj = items[idx];
            if (!this.valid_choice(type, obj.id)) continue;
            
            // we have a valid choice now
            return idx;
        } while (idx !== curidx);
        return null;
    }

    listPreviousItem(type) {
        let idx = this.listGetPrevious(type);
        if (idx === null) return;
        let e = this.obj_with_index(type, idx);
        this.onListItemClick(type, e);
    }
    
    listNextItem(type) {
        let idx = this.listGetNext(type);
        if (idx === null) return;
        let e = this.obj_with_index(type, idx);
        this.onListItemClick(type, e);
    }

    nextButtonCaption(brief) {
        let t = this;
        let stage = t.state.stage;

        let type = this.creation_step(stage);
        if (stage >= t.stages()) return 'Begin Playing';

        let sel = this.selected_obj(type);
        if (sel) {
            if (brief) return 'Select';
            let names = this.element_split_name(sel);
            if (type == 'race') 
                return 'Create ' + Util.add_article(names[0]);
            if ((type == 'city') || (type == 'faction'))
                return 'Join ' + names[0];
            if ((type == 'class') || (type == 'profession'))
                return 'Play as ' + Util.add_article(names[0]);
            return 'Select ' + names[0];
        }
        return 'Select an option to continue';
    }

/** Name form rendering */

    check_name_validity(n) {
        let t = this;
        let params = { name:n, game:t.props.nexus.gameinfo().game_name() };
        return t.fetchFile(t.props.nexus.gameinfo().script_url()+'namecheck.php', params).then((result) => {
            if (result.ok)
                return Promise.resolve(result.text());
            else
                return Promise.reject(result.statusText);
        }).catch((error) => {
            return Promise.reject(error.message);
        });
    }

    // Is this e-mail already registered?
    check_email_regis(e) {
        let t = this;
        let params = { email:e, game:t.props.nexus.gameinfo().game_name() };
        return t.fetchFile(t.props.nexus.gameinfo().script_url()+'emailcheck.php', params).then((result) => {
            if (result.ok)
                return Promise.resolve(result.text());
            else
                return Promise.reject(result.statusText);
        }).catch((error) => {
            return Promise.reject(error.message);
        });
    }

    validate_name() {
        let t = this;
        let n = t.state.name;
        if (t.error_name(n)) return;  // nothing if the basic checks fail
        if (n === t.validated_name) return;  // this name is fine
        if (t.namecheck_timer) t.props.platform.clear_timeout (t.namecheck_timer);
        else t.setState({errorNameAvail:false, nameAvailMessage: null});

        // only check every 1.5s so as not to hammer network requests needlessly
        t.namecheck_timer = t.props.platform.set_timeout (() => {
            t.check_name_validity(n).then((result) => {
                t.namecheck_timer = null;
                // if the data has since changed, we need to request a new check
                if (n !== t.state.name) {
                    t.validate();
                    return;
                }

                if (result === 'OK') {
                    t.validated_name = n;
                    t.setState({errorNameAvail:false, nameAvailMessage: null});
                }
                else
                    t.setState({errorNameAvail:true, nameAvailMessage: result});
            }).catch((error) => {
                t.namecheck_timer = null;
                t.setState({errorNameAvail:true, nameAvailMessage: error});
            });
        }, 1500);
    }

    onNameChange(val, type) {
        let state = {};
        let stripped = '';
        for (let i = 0; i < val.length; ++i) {
            let ch = val.charCodeAt(i);
            if ((ch >= 32) && (ch <= 127)) stripped += val[i];
        }
        state[type] = stripped;
        this.setState(state, () => { 
            if (type === 'name') {
                // send an AJAX request to validate the name
                this.validate_name();
            }
            if (type === 'email') {
                // send an AJAX request to validate the e-mail
                this.validate_email();
            }
            this.validate();
        });
    }

    onNameCheckbox(val, type) {
        let state = {};
        state[type] = val;
        this.setState(state, () => {
            this.validate();
        });
    }

    validate_email() {
        let t = this;
        let e = t.state.email;
        if (t.error_email(e)) return;  // nothing if the basic checks fail
        if (e === t.validated_email) return;  // this one is fine
        if (t.emailcheck_timer) t.props.platform.clear_timeout (t.emailcheck_timer);
        else t.setState({emailRegistered:false, emailChecked: false});

        // only check every 1s so as not to hammer network requests needlessly
        t.emailcheck_timer = t.props.platform.set_timeout (() => {
            t.emailcheck_running = true;
            t.setState({x:1});
            t.check_email_regis(e).then((result) => {
                t.emailcheck_timer = null;
                t.emailcheck_running = false;
                // if the data has since changed, we need to request a new check
                if (e !== t.state.email) {
                    t.validate();
                    return;
                }

                if (result === 'EXISTS') {
                    t.validated_email = e;
                    t.setState({emailRegistered:true, emailChecked: true});
                }
                else if (result === 'NEW') {
                    t.validated_email = e;
                    t.setState({emailRegistered:false, emailChecked: true});
                } else
                    t.setState({emailRegistered:false, emailChecked: false});
            }).catch((error) => {
                t.emailcheck_timer = null;
                t.emailcheck_running = false;
                t.setState({emailRegistered:false, emailChecked: false});
            });
        }, 1000);
    }

    onGenderChange(val) {
        this.setState({gender: val}, () => { this.validate(); });
    }
    onAgeChange(val) {
        this.setState({age: val}, () => { this.validate(); });
    }
    onAgeInput(val) {
        let a = (val === '') ? 0 : Number(val);
        this.setState({age: a}, () => { this.validate(); });
    }
    onAgeInputBlur(val) {
        let limits = this.age_limits();
        let a = (val === '') ? 0 : Number(val);
        if (a < limits[0]) a = limits[0];
        if (a > limits[1]) a = limits[1];
        this.setState({age: a}, () => { this.validate(); });
    }

    generateName() {
        let name = NameGen.generate();
        this.setState({name: name}, () => {
            this.validate_name();
            this.validate();
        });
    }

    renderContent(stage) {
        let t = this;
        let type = t.creation_step (stage);
        if (type === 'name') return t.renderNameForm(stage);
        return t.renderList(stage, type);
    }

    btnBack(close) {
        let t = this;
        let stage = t.state.stage;

        if ((stage > 1) && (!close)) {
            t.setState({stage:stage-1}, ()=>t.validate());
            return;
        }
        
        // we're closing
        t.props.onCancel();
    }

    btnBackToList() {
        let t = this;
        let stage = t.state.stage;
        let type = this.creation_step(stage);
        delete this.state.selection[type];
        t.setState({stage:stage});
    }

    btnNext() {
        let t = this;
        let stage = t.state.stage;
        
        if (!t.validate()) return;
        
        // we can advance
        t.enter_stage(stage + 1);
    }

    do_creation() {
        let t = this;
        if (!t.validate()) return;

        let res = { name: t.state.name, password: t.state.pass, email: t.state.email, nl_ire: t.state.nl_ire ? 'yes' : 'no', nl_game: t.state.nl_game ? 'yes' : 'no' };
        let gender = t.gender_enforced();
        if (gender === null) gender = t.state.gender;
        if (t.state.emailChecked && (!t.state.emailRegistered)) {
            res.name_first = t.state.firstName;
            res.name_last = t.state.lastName;
            res.dob = t.state.DOB;
        }

        res.creationdata = t.creation_data(t);
        if (!res.creationdata) return;
        res.creationdata = 'gender' + "\n" + gender + "\n" + res.creationdata;
        if (t.show_age()) res.creationdata = 'age' + "\n" + this.state.age + "\n" + res.creationdata;

        // add this character to their list
        let nex = this.props.nexus;
        if (nex.logged_in_nexus()) {
            let chars = this.props.chars;
            chars.char_add (t.state.name, t.state.gameID, t.state.pass, true);
        }

        t.props.onCreation(t.state.name, t.state.pass, t.state.gameID, res);
    }
}

 
