import React from 'react';

import { AetoliaFarmMap } from './farmmap'

// game info - Aetolia

export class GameInfoAetolia {
  constructor() {

    this._gauges = [];
    this._gauges.push({
      name: 'health', 'desc': 'Health', curvar: 'hp', maxvar: 'maxhp', percentage: true, color: '#ff0000', coloroff: '#7f7f7f',
      tooltip: 'Health represents your physical well-being. When your health reaches 0 you will die.'
    });
    this._gauges.push({
      name: 'mana', 'desc': 'Mana', curvar: 'mp', maxvar: 'maxmp', percentage: true, color: '#5050ff', coloroff: '#7f7f7f',
      tooltip: 'Mana represents mental strength. When you are out of mana, you will not be able to perform most mental actions.'
    });
    this._gauges.push({
      name: 'acumen', 'desc': 'Acumen', curvar: 'acu', maxvar: 'maxacu', percentage: true, color: '#00b3b3', coloroff: '#7f7f7f',
      tooltip: 'Acumen represents your mental strength for persuasion battles. When you run out of acumen, you will lose any persuasion you are engaged in.'
    });
    this._gauges.push({
      name: 'endurance', 'desc': 'Endurance', curvar: 'ep', maxvar: 'maxep', percentage: true, color: '#999900', coloroff: '#7f7f7f',
      tooltip: 'Endurance represents how worn down you are by constant activity. It goes up naturally and when you run out of endurance, you\'ll be unable to perform many taxing physical actions.'
    });
    this._gauges.push({
      name: 'willpower', 'desc': 'Willpower', curvar: 'wp', maxvar: 'maxwp', percentage: true, color: '#d000d0', coloroff: '#7f7f7f',
      tooltip: 'Willpower represents long-term mental fatigue. It will decrease when you use most mental abilities and increase naturally.'
    });

    this._balances = [];
    this._balances.push({
      name: 'balance', class: 'bal', desc: 'Balance', curvar: 'balance',
      tooltip: 'Most physical abilities require that you have Balance and will take away your Balance for a few seconds after you use the ability.'
    });
    this._balances.push({
      name: 'equilibrium', class: 'eq', desc: 'Equilibrium', curvar: 'equilibrium',
      tooltip: 'Most magical or mental abilities will require that you have \'Equilibrium\' (or simply \'eq\') and will use it up for a few seconds when you use the ability.'
    });
  }

  server_name(secondary = false) {
    if (secondary) return 'ironrealms.com';
    return 'aetolia.com';
  }

  server_port(encrypted, secondary = false) {
    if (secondary) return 12002;
    if (encrypted) return 443;
    return 80;
  }

  is_ire_game() { return true; }
  websock_dir() { return '/socket/'; }
  game_short() { return 'Aetolia'; }
  game_long() { return 'Aetolia, the Midnight Age'; };
  game_name() { return 'aetolia'; }
  root_url() { return 'https://www.' + this.server_name(false) + '/' };
  vote_url() { return this.root_url() + 'vote' };
  password_url() { return this.root_url() + 'game/recover' };
  script_url() { return 'https://client.' + this.server_name(false) + '/' };
  files_url() { return '/games/' + this.game_name(); }
  text_creation() { return '2'; }
  button_count() { return 6; }
  css_style() { return 'minimal'; }
  gauges() { return this._gauges; }
  balances() { return this._balances; }
  rift_name() { return 'Cache'; }
  command_rift_in() { return 'inc'; }
  command_rift_out() { return 'outc'; }
  money() { return 'Gold'; }
  intro_sound() { return 'aetolia'; }
  avatar_count() { return 70; }
  discord_url() { return 'https://discord.gg/x2s7fY6'; }
  facebook_url() { return 'https://www.facebook.com/AetoliaIRE'; }
  twitter_url() { return 'https://twitter.com/AetolianDevs'; }

  google_analytics() { return 'UA-33265841-1'; }

  default_tabs() {
    return {
      container_1: ['map'],
      container_2: ['affdef', 'skills', 'inventory'],
      container_3: ['room', 'who', 'news'],
      container_4: ['tasks', 'all_comm', 'quests'],
      disabled: ['gauges', 'buttons'],
      main_container: ["output_main"]
    };
  }

  // creation
  creation_stages() { return 4; }
  creation_valid_choice(type, id, creation_dlg) {
    if (type === 'city') {
      let cl = creation_dlg.selected_obj('class');
      if (!cl) return true;
      if ((cl.city === 0) || (cl.city === id)) return true;
      return false;
    }
    return true;
  }

  creation_step(step) {
    if (step === 1) return 'race';
    if (step === 2) return 'class';
    if (step === 3) return 'city';
    if (step === 4) return 'name';
    return '';
  }

  creation_short_title(step) {
    if (step === 1) return 'Race';
    if (step === 2) return 'Class';
    if (step === 3) return 'City';
    if (step === 4) return 'Description';
    return '';
  }

  creation_title(step) {
    if (step === 1) return 'Select Your Race';
    if (step === 2) return 'Select Your Class';
    if (step === 3) return 'Select Your City';
    if (step === 4) return 'Describe Your Character';
    return '';
  }

  creation_prepare_data(data) {
    let url = 'https://www.aetolia.com/local/nexus_data/';
    let baseurl = url;
    data['class'] = data['guild'];
    for (let r in data['race']) {
      let img = data['race'][r].imageURL;
      data['race'][r].imageURL = url + 'races-new/' + img;
      data['race'][r].mobileImageURL = url + 'mobile/races/' + img;
      data['race'][r].thumbImageURL = baseurl + 'races-new/thumbnails/' + img;
      let name = data['race'][r].name.toLowerCase();
    }
    for (let c in data['class']) {
      let img = data['class'][c].imageURL;
      data['class'][c].imageURL = url + 'classes-new/' + img + '?v=2';
      data['class'][c].mobileImageURL = url + 'mobile/classes/' + img;
      data['class'][c].thumbImageURL = baseurl + 'classes-new/thumbnails/' + img + '?v=2';
    }
    for (let c in data['city']) {
      let img = data['city'][c].imageURL;
      data['city'][c].imageURL = url + 'cities-new/' + img;
      data['city'][c].mobileImageURL = url + 'mobile/cities/' + img;
      data['city'][c].thumbImageURL = baseurl + 'cities-new/thumbnails/' + img;
    }

    data['class'].sort(function compare(a, b) {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    return data;
  }

  genders() { return ['Male', 'Female', 'Genderless', 'Non-binary']; }

  creation_genderreq(type, e) {
    if (type !== 'race') return null;
    let req = e.genderReq;
    if (req === 0) return null;
    let list = this.genders();
    return list[req - 1];
  }
  creation_genderreq_text(type, e) {
    let g = this.creation_genderreq(type, e);
    if (!g) return '';
    return 'This race can only be ' + g + '.';
  }
  creation_restriction(type, e, creation_dlg) {
    if (type !== 'city') return null;
    // Can they join any other city?
    let allow = creation_dlg.obj_allowed_list(type);
    if (allow.length !== 1) return;
    return allow[0];
  }
  creation_restriction_text(type, e, creation_dlg) {
    let allow = this.creation_restriction(type, e, creation_dlg);
    if (!allow) return null;
    return 'Your class must begin in ' + allow.name + ' to start with.';
  }
  element_title(type, e, creation_dlg) {
    let name = e.name;
    let names = creation_dlg.element_split_name(e);
    if (names[1]) name = names[0];

    if (type === 'race') {
      if (e.title && e.title.length)
        return e.title;
      return name;
    }
    if ((type === 'city') || (type === 'faction')) {
      if (e.title && e.title.length)
        return e.title;
      return name;
    }
    if ((type === 'class') || (type === 'guild') || (type === 'profession')) {
      if (e.title && e.title.length)
        return e.title;
      return name;
    }
  }

  show_age() { return true; }
  age_limits(data, creation_dlg) { return [data.ageLowerLimit, data.ageUpperLimit, 18]; }

  creation_data(creation_dlg) {
    let race = creation_dlg.selected_obj('race');
    let cl = creation_dlg.selected_obj('class');
    let city = creation_dlg.selected_obj('city');
    if ((!race) || (!cl) || (!city)) return null;
    let age = creation_dlg.state.selection['age'];

    return "raceID\n" + race.id + "\nguildID\n" + cl.id + "\ncityID\n" + city.id + "\nage\n" + age;
  }

  creation_nameform_image(data, creation_dlg) {
    let race = creation_dlg.selected_obj('race');
    if (!race) return null;
    return race.imageURL;
  }


  game_settings_keys() {
    return ['display_farmmap', 'display_largemap'];
  }

  game_settings_default(key) {
    if (key === 'display_farmmap') return true;
    if (key === 'display_largemap') return false;

    return undefined;
  }

  game_settings_caption(key) {
    if (key === 'display_farmmap') return 'Display the farm map';
    if (key === 'display_largemap') return 'Display large farm map';
    return undefined;
  }

  game_settings_tooltip(key) {
    if (key === 'display_farmmap') return 'If checked, the client will display a farm map while your character is inside one.';
    if (key === 'display_largemap') return 'If checked, the client will display a bigger farm map.';
    return undefined;
  }

  game_parse_content_window(nexus, type, content) {
    if (type !== 'farmmap') return undefined;

    let s = nexus.settings();
    if (!s.display_farmmap) return 'hide';

    let data = null;
    try {
      data = JSON.parse(content);
    } catch (error) {
      console.error(error);
      return null;
    }

    return (<AetoliaFarmMap datahandler={nexus.datahandler()} settings={s} data={data} />);
  }

  introText() {
    return [
      'In the shadowy realm of Aetolia, you can choose from one of many unique classes. Become a formidable Cabalist, wielding dark sorcery, or a nimble Wayfarer, mastering the secrets of nature.',
      'Explore a world filled with eerie swamps, ancient ruins, and bustling cities, each teeming with mysteries and challenges.',
      'Immerse yourself in deep role-playing, engage in fierce combat, and refine your skills with complex crafting. Form alliances, navigate through political intrigue, and shape the ever-evolving story of Aetolia.',
      'Your adventure in Aetolia begins now.'
    ];
  }

  introButtonColor() { return '#BE3030'; }

  backgroundImage() {
    return this.files_url() + '/images/background.jpg';
  }

  creationImage() {
    return this.files_url() + '/images/creation.jpg';
  }

  blurb1() {
    return 'Characters in Aetolia talk, trade, fight, plot and even kill and eat each other.';
  }

  blurb2() {
    return 'They roam the continents and explore dungeons, killing (or helping) foul creatures, gather gold and treasure while increasing in power and prestige.';
  }

  blurb3() {
    return 'Unlike other fantasy settings, Aetolia enables you to play characters of all alignments and creeds, and it\'s entirely in the hands of the player whether to become a hated villain, vaunted hero, or anything in between.';
  }
};
